.info-container {
    background-color: rgb(255, 255, 255);    
    min-height: 55vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    /*centers in x and y*/
    justify-content: center;

    align-items: center;
    object-fit: contain;
}

 #light {
    background-color: white;
    color: #000000;
  }

  #dark {
    background-color: rgb(0, 0, 0);
    color: #ffffff;
  }



.infoboxes-container {
    background-color: none;    
    min-height: 55vh;
    width: 50%;
    display: flex;
    display: grid;

    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(5, auto);
    grid-row-gap: 1000px;
}

.list_boxes {
    margin-bottom: 50px;
    list-style: none;
  }

.infobox {
    background-color: none;
    width:calc(100px + 10vw);
    height: calc(200px + 2vw);
    background-color: none;    
    display: flex;
    flex-flow:column;
    align-items: center;
    position: relative;
    
    margin:4vw;
}

.textbox {
    width:100px;
    height: calc(25px + 10vw);
    background-color: none;    
    display: flex;
    flex-flow:column;
    align-items: center;
    justify-content: center;
    position: relative;
    float: right;
}





#light hc {
    font-weight: 900;
    color: rgb(0, 0, 0);
    font-size: calc(25px + 0.7vw);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#light iconPhone {
    color: rgb(27, 131, 50);
    font-size: calc(30px + 1vw);
}

#light iconMail {
    color: rgb(40, 28, 218);
    font-size: calc(30px + 1vw);
}

#light hc2 {
    font-weight: 500;
    color: rgb(0, 0, 0);
    font-size: calc(15px + 0.7vw);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}



#dark hc {
    font-weight: 900;
    color: rgb(255, 255, 255);
    font-size: calc(25px + 0.7vw);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#dark iconPhone {
    color: rgb(27, 131, 50);
    font-size: calc(30px + 1vw);
}

#dark iconMail {
    color: rgb(40, 28, 218);
    font-size: calc(30px + 1vw);
}

#dark hc2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: calc(15px + 0.7vw);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}



@media only screen and (max-width: 800px) {
    .infobox {
      float: center;
    }
  }

  @media only screen and (min-width: 800px) {
    .infobox {
      float: left;
    }
  }