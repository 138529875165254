.cards {
    background: #fff;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  #light {
    background-color: white;
    color: #000000;
  }

  #dark {
    background-color: rgb(0, 0, 0);
    color: #ffffff;
  }


  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
    width: 80%;
  }
  
  .cards__items {
    margin-bottom: 50px;
    list-style: none;
  }
  

  h10{
    font-size: calc(10px + 1vw);
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }


