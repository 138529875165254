.vid {
    background-color: rgb(0, 25, 46);
    background: url('../../../../public/images/img-tempHome.png') center center/cover no-repeat; /*a temporary background until the video is loaded*/
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
    margin-top: -60px;
}

.arrow-container{
    z-index: 5;
    color: rgb(255, 255, 255);
    font-size: 60px;
    display: flex;
    background-color: none;
    /*centers in x and y*/
    justify-content: center;

    align-items: center;
    margin-top: calc(5% + 10vw);
}

.selfie-container{
    display: flex;
    flex-direction: column;
    background-color: none;
    /*centers in x and y*/
    justify-content: center;

    align-items: center;
    margin-top: calc(450px + 10vw);
    margin-bottom: calc(-200px + 8vw);
}
.home-self {
    position: absolute;
    margin-top: calc(-1200px + 7vw );
    width: calc(90px + 5vw);
    height: calc(90px + 5vw);
    box-sizing: border-box;
    border: 5px white solid;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    
    background: url('../../../../public/images/port1.jpg') center center/cover no-repeat;

    animation: bloob 10s ease-in-out infinite;
}

.home-self:hover{
    background: url('../../../../public/images/port3.jpg') center center/cover no-repeat;
}

@keyframes bloob {
    0%, 100% {
      border-radius:65% 100% 80% 100%
    }
    25% {
      border-radius:100% 80% 100% 65%
    }
    50% {
      border-radius:80% 100% 65% 100%
    }
    75% {
      border-radius:100% 65% 100% 80%
    }
  }
  

.hero-container {
    /*background: url('../../public/images/img-home.jpg') center center/cover no-repeat;*/
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    /*centers in x and y*/
    justify-content: center;

    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container>h1 {
    color: white;
    font-size: calc(20px + 2vw);
    margin-top: -300px;
}

.hero-container>p {
    margin-top: 8px;
    color: white;
    font-size: calc(12px + 1vw);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 50px;
}

.hero-btns .btn {
    margin: 0px 10px 10px 0px;
}

.fa-play-circle {
    margin-left: 4px;
}



@media screen and (max-width: 768px) {

    .home-self{
        border: 2px white solid;
    }


    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}