video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.cv-container{
    background-color: rgb(255, 255, 255);
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;

    /*centers in x and y*/
    justify-content: center;

    align-items: center;
    object-fit: contain;
}

#light {
    background-color: white;
    color: #000000;
}

#dark {
    background-color: rgb(0, 0, 0);
    color: #ffffff;
}


.cv-container>h1 {
    color: white;
    font-size: 80px;
    margin-top: -200px;
}

.cv-container>p {
    margin-top: 8px;
    
    color: white;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.download-wrapper{
    background-color: none;
    width: 10%;
    height: 20%;
    position: fixed;
    z-index: 3;
    margin-top: 100px;
    margin-right: 70%;
    align-items: center;
}


.btnD{
    color: rgb(0, 0, 0);
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    width: 138px;
    outline: none;
    border: none;
    font-size: 18px;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
}



.btnD:hover{
    background-color: rgb(204, 204, 204);
    cursor: pointer;
}

.btnD:active{
   background-color: rgb(68, 68, 68);
}
