.project-container{
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}

#light {
  background-color: white;
  color: #000000;
}

#dark {
  background-color: rgb(0, 0, 0);
  color: #ffffff;
}



#light h6 {
  align-self: center;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: calc(15px + 1.5vw);
}

#dark h6 {
  align-self: center;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: calc(15px + 1.5vw);
}


.cards2 {
    padding: 4rem;
    background: none;
  }
  

  
  .projects__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  
  .projects__wrapper {
    position: relative;
    margin: 50px 0 45px;
    width: 80%;
  }
  
  .cards__items {
    margin-bottom: 50px;
    list-style: none;
  }
  
  .cards__item {
    background-color: white;
    display: flex;
    flex: 1;
    margin: 0 2rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 1vw;
    font-weight: 700;
    color: #fff;
    background-color: #000000;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #000000;
    font-size: 1.1vw;
    line-height: 1vw;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {

    .cards__item {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: 600px) {

    .projects__wrapper {
      width: 130%;
    }

  


  }


  .information {
    background-color: white;
    display: flex;
    min-width: 45%;
    margin: 0 2rem;
    flex-flow: column;
    max-width: calc((100%) - 60px);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    text-align: left;

  }

  .info_figures{
    display: flex;
    display: grid;
    padding: 1rem;
    background: none;
    justify-content: center;
    margin-top: 15%;
    width: 100%;
    flex-flow: row;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
  }

  .figures{
    display: flex;
    background: none;
    align-items: center;
    color: #000000;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    width:20px;
    height: 100%;
    font-size:2vw;
    margin-right: 1.2vw;
  }

  ptext{
    color: black;
    font-size:calc(7px + 1.0vw);
    padding: 1vw;
  }

  h4 {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: calc(10px + 1.4vw);
    margin-top: 1.5vw;
  }