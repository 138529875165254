.pics {
    padding: none;
    background: none;
  }
  
  h1 {
    text-align: center;
  }
  
  .pics__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  
  .pics__wrapper {
    position: relative;
    margin: 50px 0 45px;
    width: calc(225px + 37vw);
  }
  
  .pics__items {
    margin-bottom: 5px;
    list-style: none;
  }
  
  .pics__item {
    display: flex;
    flex: 1;
    margin: 0 2rem;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  }
  
  
  .pics__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 150%;
    overflow: hidden;
  }
  
  

  
  .pics__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: flex;
    transition: all 0.2s linear;
  }
  

  
 